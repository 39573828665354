@import '../../styles/theme.scss';

button::-moz-focus-inner,
input::-moz-focus-inner {
    padding: 0;

    border: 0;
}

.button {
    font-weight: 600;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 200px;
    min-height: 54px;
    padding: 10px 20px;
    cursor: pointer;
    transition: 0.2s;

    color: $gray-100;
    border-radius: 8px;
    background-color: $gold-500;

    &:disabled {
        cursor: not-allowed;

        background-color: $gray-400;
    }

    &:not(:disabled) {
        border: 1px solid $gold-500;
        &:hover {
            color: $gold-500;
            background-color: $gray-100;
        }
    }
    svg {
        margin-left: 8px;
    }

    &--olive {
        background-color: $olive-500;

        &:not(:disabled) {
            border: 1px solid $olive-500;

            &:hover {
                color: $gold-500;
                background-color: $olive-500;
            }
        }
    }
    &--red {
        background-color: $red-500;

        &:not(:disabled) {
            border: 1px solid $red-500;

            &:hover {
                color: $red-500;
                background-color: $gray-100;
            }
        }
    }

    &--text {
        min-width: 0;
        min-height: 0;
        padding: 0;

        color: currentColor;
        border: none;
        background-color: transparent;

        &:not(:disabled) {
            border: none;
            &:hover {
                color: $gold-500;
                background-color: transparent;
            }
        }
        &:not(:disabled) {
            border: none;
            &:hover {
                color: $gold-500;
                background-color: transparent;
            }
        }
    }
    &--textRed {
        min-width: 0;
        min-height: 0;
        padding: 0;

        color: $red-500;
        border: none;
        background-color: transparent;

        &:not(:disabled) {
            border: none;
            &:hover {
                color: $gold-500;
                background-color: transparent;
            }
        }
    }
    &--ghost-white {
        color: white;
        border: 1px solid white;
        background-color: transparent;
        &:not(:disabled) {
            border: 1px solid white;
        }
        &:hover {
            color: $green-900;
            border: 1px solid white;
            background-color: $gray-100;
        }
        &-red {
            color: white;
            border: 1px solid white;
            background-color: transparent;
            &:not(:disabled) {
                border: 1px solid $gray-100;
            }
            &:not(:disabled):hover {
                color: $red-500;
                background-color: $gray-100;
            }
        }
        &-green {
            color: white;
            border: 1px solid white;
            background-color: transparent;
            &:not(:disabled) {
                border: 1px solid $gray-100;
            }
            &:not(:disabled):hover {
                color: $green-500;
                background-color: $gray-100;
            }
        }
    }

    &--ghost-black {
        color: $green-900;
        border: 1px solid $green-900;
        background-color: transparent;
        &:not(:disabled) {
            border: 1px solid $gray-900;
        }
        &:not(:disabled):hover {
            color: $green-100;
            border: 1px solid $green-900;
            background-color: $green-500;
        }
    }
    &--ghost-gold {
        color: $gold-500;
        border: 1px solid $gold-500;
        background-color: transparent;
        &:not(:disabled) {
            border: 1px solid $gold-500;
        }
        &:not(:disabled):hover {
            color: $gray-100;
            border: 1px solid $gold-500;
            background-color: $gold-500;
        }
    }

    &--secondary {
        color: $gray-900;
        border-color: $gray-100;
        background-color: $gray-100;

        &:not(:disabled):hover {
            color: $gold-500;
        }
    }
}
// .button__next,.button__repeat {
//     color: $gold-500;
//     background-color: var(--dark);
// }

// .button:hover {
//     /* opacity: .8 */
// }

// .button:hover svg {
//     transform: translateX(3px) scaleY(-1);
// }

// .button__next:hover {
//     /* background-color: $gold-500;
// 	color: var(--dark);
// 	opacity: 1; */
// }

// .button__next:hover svg {
//     transform: translateX(3px);
// }

// .button__repeat:hover svg {
//     transition: .4s;
//     transform: rotate(180deg);
// }

// .button__repeat svg {
//     transition: 0s;
// }
