@import '../../styles/theme.scss';

.header {
    box-sizing: border-box;
    width: 100%;
    padding: 20px 20px 0;
    transition: 0.2s all;

    color: $gold-100;
    background-color: $green-900;

    @include phone-only {
        padding: 10px 20px 0;
    }
    .light & {
        color: $gray-900;
        background-color: $green-100;
    }

    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1440px;
        margin: 0 auto;
    }

    &__logo {
        max-width: 130px;
        padding-top: 10px;
        @media only screen and (min-width: 600px) {
            max-width: 200px;
            padding-top: 0;
        }
    }

    &__items {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &--hide {
            display: none;
        }
    }

    &__item {
        font-size: 16px;
        font-weight: 700;

        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 14px;
        padding: 5px 10px;
        cursor: pointer;
        transition: 0.2s;
        text-decoration: none;

        color: $gray-100;

        @include phone-only {
            height: 100%;
            margin: 0 10px;
            padding: 0;
        }

        &--notify {
            position: relative;
            &::after {
                position: absolute;
                top: 0;
                right: 0;
                display: block;
                width: 10px;
                height: 10px;
                content: '';
                transition: 1s;

                opacity: 1;
                border-radius: 50%;
                background-color: red;
            }
        }

        &:hover {
            color: $gold-500;
        }
        &.active {
            position: relative;

            color: $gold-500;
            &:hover {
                cursor: default;

                color: $gold-500;

                .light & {
                    color: $gold-500;
                }
            }

            .light & {
                color: $gold-500;
            }
        }
        .light & {
            color: $gray-900;
        }
    }
    &__results {
        display: none;

        @media only screen and (min-width: 600px) {
            display: block;
        }
    }

    &__user {
        line-height: normal;

        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0;
        padding: 10px;
        padding-right: 0;

        @media only screen and (min-width: 600px) {
            margin-left: 30px;
            padding: 10px;
        }
    }

    &__user-points {
        font-weight: 600;

        margin: 0;
    }

    &__user-diamond {
        width: 22px;
        margin-left: 4px;
    }

    &__user-points-wrapper {
        display: flex;
        justify-content: center;
    }

    &__crown {
        display: none;
        width: 50px;
        height: auto;

        @media only screen and (min-width: 600px) {
            display: block;
        }
    }

    &__diamonds {
        width: 16px;
        height: auto;
    }

    &__desktop {
        @include mobile {
            display: none;
        }
    }

    &__user-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
    }

    &__mobileMenu {
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        display: none;
        width: 100%;
        height: 100%;
        margin-top: 0;
        padding: 72px 0 0;
        list-style: none;
        transition: 0.4s;
        transform: translateX(100%);

        background-color: $olive-500;

        @include mobile {
            display: block;
        }

        &--opened {
            transform: translateX(0);
        }

        &__item {
            font-size: 16px;
            font-weight: 600;

            color: $light;
            border-top: 1px solid $white;
        }

        &__link {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;
            text-decoration: none;

            color: $light;
            &.active {
                color: $gold-500;
            }

            &--notify {
                &::before {
                    top: 5px;
                    left: calc(50% - 5px);
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    margin-right: 5px;
                    content: '';

                    border-radius: 50%;
                    background-color: red;
                }
            }
        }
    }

    &__mobileTrigger {
        z-index: 11;
        display: none;
        padding: 10px;
        cursor: pointer;

        @include mobile {
            display: block;
        }

        &--opened {
            position: fixed;
            top: 20px;
            right: 20px;
            .header__hamburger {
                &:nth-child(1) {
                    transform: rotate(45deg) translate(5px, 5px);
                }

                &:nth-child(2) {
                    opacity: 0;
                }

                &:nth-child(3) {
                    transform: rotate(-45deg) translate(7px, -7px);
                }
            }
        }
    }

    &__hamburger {
        width: 30px;
        height: 3px;
        margin-top: 5px;
        transition: 0.2s;

        border-radius: 5px;
        background-color: $white;
        .light & {
            background-color: $gray-900;
        }
    }
}

.wobble {
    animation: wobble 1s ease forwards;
}

@keyframes wobble {
    0%,
    100% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }

    15% {
        -webkit-transform: scale(1.05) translateX(-10px) rotate(-10deg);
        transform: scale(1.05) translateX(-10px) rotate(-10deg);
    }

    30% {
        -webkit-transform: scale(1.1) translateX(calc(10px / 2)) rotate(10deg);
        transform: scale(1.1) translateX(calc(10px / 2)) rotate(10deg);
    }

    45% {
        -webkit-transform: scale(1.15) translateX(calc(-10px / 2)) rotate(calc(-10deg / 1.8));
        transform: scale(1.15) translateX(calc(-10px / 2)) rotate(calc(-10deg / 1.8));
    }

    60% {
        -webkit-transform: scale(1.1) translateX(calc(10px / 3.3)) rotate(calc(10deg / 3));
        transform: scale(1.1) translateX(calc(10px / 3.3)) rotate(calc(10deg / 3));
    }

    75% {
        -webkit-transform: scale(1.05) translateX(calc(-10px / 5.5)) rotate(calc(-10deg / 5));
        transform: scale(1.05) translateX(calc(-10px / 5.5)) rotate(calc(-10deg / 5));
    }
}
