@import '../../styles/theme.scss';

.formPage {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;

    background-color: $green-900;

    .form-row {
        &--noMt {
            margin-top: 0;
        }
        p {
            margin: 0;
        }
    }

    &--signup {
        .formPage__formContainer {
            flex-direction: row-reverse;

            @include tablet-and-phone {
                flex-direction: column-reverse;
            }
        }
    }

    &__formCheckbox {
        display: flex;
        cursor: pointer;

        color: #000;

        input {
            height: 13px;
        }
    }

    &__desc {
        &--redirect {
            font-weight: 600;

            margin-top: 60px;

            color: $gold-500;
        }
    }
    &__content {
        margin: 50px auto;
    }
    &__col {
        &--left {
            flex: 55% 0 0;
            padding: 50px 80px 80px;

            background-color: $gray-100;

            @include tablet-and-phone {
                padding: 30px;
            }

            .formPage__title {
                color: $gray-1000;
            }
        }
        &--pass {
            flex: 100% 1 1;
        }
        &--right {
            display: flex;
            align-items: center;
            flex: 45% 0 0;
            flex-direction: column;
            justify-content: center;
            padding: 30px 50px;

            background-color: $green-700;
            background-image: url('../../../public/images/hero-bg.png');
            background-size: cover;

            @include tablet-and-phone {
                padding: 30px;
            }
            .formPage__button {
                width: auto;
                min-width: 230px;
                margin-top: 0;

                border: 1px solid currentColor;
            }
        }
    }
    &__heading {
        font-size: 20px;

        margin: 50px 0 50px;

        @include tablet-and-phone {
            margin: 30px;
        }
        &--licytapka {
            font-family: 'Oleo Script Swash Caps';
            font-size: 30px;

            margin-right: 8px;

            color: $yellow-500;
        }
    }

    .error,
    &__error {
        font-size: 13px;

        color: red;
    }

    &__formControl {
        width: 100%;
        min-height: 56px;
        padding: 12px;

        border: 1px solid $green-500;
        border-radius: 10px;
    }

    &__formGroup {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-top: 30px;
        margin-bottom: 10px !important;

        p {
            text-align: left;
        }
    }

    &__formContainer {
        display: flex;
        overflow: hidden;
        max-width: 1100px;
        min-height: 500px;
        margin: 0 auto;

        border-radius: 20px;
        background-color: $gray-100;

        @include tablet-and-phone {
            flex-direction: column;
            margin: 30px;
        }

        &--short {
            min-height: 250px;
        }
    }

    &__button {
        font-weight: 400;

        width: 100%;
        margin-top: 30px;

        color: white;

        &.disabled {
            background-color: $gray-500;

            &:hover {
                cursor: auto;
            }
        }
    }

    &__formLabel {
        color: black;
    }

    &__title {
        font-family: 'Nova Square';
        font-size: 30px;
        font-weight: 400;

        margin-top: 0;
        margin-bottom: 60px;

        @include tablet-and-phone {
            font-size: 25px;

            margin-bottom: 10px;
        }
    }

    &__noAccount {
        font-size: 13px;

        margin-top: 60px;

        color: $gray-100;
    }

    &__link {
        text-decoration: underline;

        color: $gray-100;
        &--forgetPass {
            font-size: 13px;

            margin-top: 10px;
            text-decoration: none;

            color: $gold-500;
        }
    }
    &__desc {
        max-width: 400px;
        margin: 0 auto;

        color: $green-900;
    }
}
