@import '../../styles/theme.scss';
.lessons {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;

    color: $green-900;
    background-color: $green-100;
    .allTests__tag {
        font-size: 11px;
        font-weight: 600;

        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-height: 22px;
        margin: 3px;
        padding: 0 7px;
        cursor: pointer;
        transition: 0.2s;
        letter-spacing: 1px;

        color: var(--light);
        border-radius: 8px;
        background-color: $green-400;
    }

    & &__container {
        align-items: center;
        margin: 0 auto;
        padding: 50px 20px;

        gap: 50px;
    }

    &__title {
        font-family: 'Nova Square';
        font-size: 40px;

        width: 100%;
        margin-bottom: 0;
        text-align: left;

        color: $gold-500;
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 100%;

        gap: 40px;
    }
    &__paper {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        padding: 35px;

        gap: 50px;

        &--game {
            flex-direction: column;
            min-height: 780px;

            color: $gray-100 !important;
            background-color: $green-900 !important;

            @include tablet-and-phone {
                width: calc(100vw + 40px);
                margin: 0 -20px;
                padding: 20px;

                border-radius: 0 !important;
            }
        }

        &--40 {
            flex: 40% 1 0;
        }
        &--60 {
            flex: 60% 1 0;
        }
        &--center {
            align-items: center;
            flex-direction: column;
            padding: 35px;

            gap: 20px;
        }

        &--between {
            align-items: center;
            flex-direction: column;
            justify-content: space-between;
            padding: 35px;

            gap: 20px;
        }

        &--evenly {
            align-items: center;
            flex-direction: column;
            justify-content: flex-start;
            padding: 35px;

            gap: 20px;
            @include tablet-and-phone {
                padding: 35px 10px;
            }
        }
    }
    &__paperTitle {
        font-size: 30px;
        font-weight: 600;

        width: 100%;
        margin: 0 0 16px 0;
        text-align: left;

        color: $green-900;
        &:not(:nth-child(1)) {
            margin-top: 60px;
        }
    }
}

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@media print {
    body {
        margin: 0 !important;
    }
}

.main-container {
    font-family: 'Lato';

    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.ck-content {
    word-break: break-word;
}

.editor-container_classic-editor .editor-container__editor {
    width: 100%;
}

.lesson {
    &__homework {
        background-color: $gray-100;
        &--disabled {
            pointer-events: none;

            background-color: $gray-400;

            * {
                color: $gray-800 !important;
                border-color: $gray-800 !important;
            }
        }
    }
    &__editLesson {
        background-color: $gray-100;
    }
}
