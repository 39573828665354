@import '../../styles/theme.scss';

.backoffice {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    transition: 2s all;

    color: $green-900;
    background-color: $white;

    .allTests__tag {
        font-size: 11px;
        font-weight: 600;

        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-height: 22px;
        margin: 3px;
        padding: 0 7px;
        cursor: pointer;
        transition: 0.2s;
        letter-spacing: 1px;

        color: var(--light);
        border-radius: 8px;
        background-color: $green-400;
    }

    &__error {
        font-size: 12px;
        font-weight: bold;

        margin-top: 5px;

        color: $hearts;
    }
    .biddingUI {
        margin-top: 30px;
    }

    &__help {
        max-width: 100%;
        margin-bottom: 30px;
    }

    &__add {
        margin: 20px;
    }
    .wrapper {
        flex-grow: 1;
        justify-content: flex-start;
    }
    &__pagination {
        display: flex;
        justify-content: center;
        margin: 40px 0;
    }
    & &__test--edit {
        padding: 70px 50px;

        @include small-screens {
            padding: 10px;
        }

        .backoffice__test-content {
            flex-wrap: wrap;

            @include small-screens {
                flex-direction: column;
            }
        }
    }

    &__cards-tags-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
    }

    &__cards {
        width: 300px;
        margin-bottom: 20px;
    }
    &__bidSequenceWrapper {
        display: flex;
        flex: 50% 0 0;
    }
    &__cards-tags {
        flex: 50% 0 0;
    }
    &__tests {
        width: 100%;
    }
    &__buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px 0;

        @include mobile {
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
        }
    }
    &__heading {
        font-weight: bold;

        margin: 8px;
        text-transform: uppercase;

        color: $dark;
    }

    &__bids {
        display: flex;
        justify-content: flex-end;

        color: $gray-100;

        @include mobile {
            flex-wrap: wrap;
            width: 100%;

            gap: 20px;
        }
    }
    &__seq {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;

        @include mobile {
            display: block;
            overflow: auto;
            width: 100%;
        }
    }

    &__tags {
        display: flex;
        justify-content: flex-start;

        gap: 20px;
    }
    &__edit-tags {
        display: flex;
        justify-content: flex-start;
        margin-top: 40px;

        gap: 20px;
        @include mobile {
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    &__warning {
        position: absolute;
        right: 60px;
        bottom: 60px;
        margin: 20px 0 0 0;

        color: $red-700;

        @include mobile {
            right: 10px;
            bottom: 0;
        }
    }

    &__test-content {
        display: flex;
        align-items: flex-start;
        flex-grow: 1;
        justify-content: space-evenly;

        gap: 50px;
        @include mobile {
            flex-wrap: wrap;

            gap: 20px;
        }
    }

    &__test-wrapper {
        @include mobile {
            width: 100%;
        }
    }

    &__test {
        position: relative;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        padding: 20px 50px;

        border-top: 1px solid var(--dark);

        @include mobile {
            flex-wrap: wrap;
            justify-content: center;
            padding: 20px;
        }
        gap: 20px;

        &:nth-of-type(odd) {
            background-color: $gold-100;
        }
    }

    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__noTests {
        font-family: 'Nova Square';
        font-size: 40px;

        margin: 100px;

        color: $gold-500;
    }

    &__testName {
        position: absolute;
        right: 10px;
        bottom: 10px;
        margin: 20px 0 0 0;

        color: $gray-700;
    }
}

.backoffice__test-number {
    font-weight: bold;

    display: flex;
    align-items: center;
    margin-right: 20px;

    color: var(--dark);
}

.test-sequence-grid {
    display: grid;
    // overflow: hidden;

    border: 1px solid light;
    border-radius: 4px;

    grid-template-columns: 1fr 1fr 1fr auto auto;

    .test-sequence-grid__item {
        font-size: 16px;
        font-weight: 700;
        font-style: normal;
        line-height: normal;

        position: relative;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        width: 47px;
        height: 35px;
        padding: 4px;
        cursor: auto;
        transition: transform 0.2s 0.02s;

        border-radius: 8px;
        box-shadow: -2px 4px 4px 0 #0006;

        .backoffice__test--edit &--edit {
            cursor: pointer;
            &:hover {
                transform: translateY(-5px);
            }
        }

        &.shouldAlert-true {
            position: relative;
            &::after {
                font-size: 8px;

                position: absolute;
                top: auto;
                right: 0;
                bottom: -3px;
                left: 0;
                padding: 0 5px;
                content: 'ALERT';

                color: $gray-100;
                border: 1px solid $gray-100;
                border-radius: 0 0 8px 8px;
                background-color: $blue-700;
            }
        }

        &.focused {
            outline: 3px solid $diamonds;
            // &::after {
            //     position: absolute;
            //     top: 0;
            //     right: 0;
            //     bottom: 0;
            //     left: 0;
            //     display: flex;
            //     align-items: center;
            //     justify-content: center;
            //     content: '?';

            //     color: $dark;
            //     border-radius: 7px;
            //     background-color: $diamonds;
            // }
        }
    }
}
.test-sequence-grid {
    color: $gray-100;
}

.test-sequence-grid__word {
    font-size: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 50px;
    margin: 4px;
}

.test-sequence-grid__item-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 8px;

    border-right: 1px solid currentColor;
    border-bottom: 1px solid currentColor;
    background-color: var(--hearts);

    &.bid-W,
    &.bid-N,
    &.bid-E {
        min-width: 64px;
    }
}

.test-sequence-grid__answers {
    justify-content: flex-start;
}

.test-sequence-grid__seat {
    font-weight: bold;

    margin: 8px;

    color: var(--dark);
}

.test-sequence-grid__end-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    &.focused {
        background-color: $diamonds;
    }
}

.test-sequence-grid__end-wrapper .test-sequence-grid__word {
    font-weight: bold;

    margin: 2px;
}

.test-sequence-grid.NONE .test-sequence-grid__item-wrapper,
.test-sequence-grid.EW_RED .test-sequence-grid__item-wrapper.bid-N,
.test-sequence-grid.EW_RED .test-sequence-grid__item-wrapper.bid-S,
.test-sequence-grid.NS_RED .test-sequence-grid__item-wrapper.bid-E,
.test-sequence-grid.NS_RED .test-sequence-grid__item-wrapper.bid-W {
    background-color: var(--clubs);
}

.test-sequence-grid.NOT_SPECIFIED .test-sequence-grid__item-wrapper {
    background-color: $dark;
}

.test-sequence-grid__item.sequence-item--null,
.test-sequence-grid__item.sequence-item--BA {
    background-color: var(--light);
}

.test-sequence-grid__item .sequence__number {
    margin: 0;
    margin-right: 2px;
}

.test-sequence-grid__item .sequence__special {
    padding: 0;
}

.test-sequence-grid__item .sequence__bid-wrapper {
    flex-direction: row;
}
