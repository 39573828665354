@import '../../styles/theme.scss';

.hero {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    padding: 20px;
    text-align: center;

    color: $gray-100;
    background-color: $green-400;
    background-image: url('../../../public/images/cards-hero.png');
    background-size: cover;

    @include tablet-and-phone {
        min-height: 130px;
    }

    &__title {
        font-family: 'Nova Square';
        font-size: 40px;
        font-weight: normal;

        margin: 0;
    }

    &__desc {
        font-size: 18px;

        margin-bottom: 20px;
    }
}
