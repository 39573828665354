@import '../../styles/theme.scss';
.profile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;

    color: $green-900;
    background-color: $green-100;
    &__avatar svg {
        @include tablet-and-phone {
            width: 100px;
            height: 100px;
        }
    }
    &__container,
    .profile.light.statistics &__container.container {
        align-items: center;
        margin: 0 auto;
        padding: 50px 20px !important;

        gap: 20px;
    }

    &__data--bold {
        font-family: 'Nova Square';
        font-weight: 900;
    }
    &__title {
        margin-top: 100px;
        margin-bottom: 20px;
    }

    &__username {
        font-family: 'Nova Square';
        font-size: 40px;

        margin: 0;

        color: $green-900;

        @include tablet-and-phone {
            font-size: 25px;

            margin-bottom: 10px;
        }
    }

    &__email {
        font-size: 20px;
        font-weight: 600;

        margin: 0;
        margin-bottom: 20px;

        color: $green-900;

        @include tablet-and-phone {
            font-size: 16px;
        }
    }

    &__paper {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        padding: 35px;

        gap: 50px;
        @include tablet-and-phone {
            align-items: center;
            flex-direction: column;
            padding: 25px;

            gap: 20px;
        }

        &--bigStatistics {
            padding-left: 0;
        }

        &--40 {
            flex: 40% 1 0;
        }
        &--60 {
            flex: 60% 1 0;
        }
        &--center {
            align-items: center;
            flex-direction: column;

            gap: 20px;
        }

        &--between {
            align-items: center;
            flex-direction: column;
            justify-content: space-between;

            gap: 20px;
        }

        &--evenly {
            align-items: center;
            flex-direction: column;
            justify-content: flex-start;

            gap: 20px;
        }
        &--center {
            align-items: center;
            flex-direction: column;

            gap: 20px;
        }
    }
    &__paperTitle {
        font-size: 30px;
        font-weight: 600;

        margin: 0 0 30px 0;

        color: $green-900;

        @include tablet-and-phone {
            font-size: 20px;

            margin: 0;
        }
    }

    &__paperCol {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;

        gap: 10px;
        @include tablet-and-phone {
            align-items: center;
            width: 100%;

            gap: 0;
        }
    }

    &__profileInfo {
        display: flex;
        align-items: center;
        justify-content: center;

        gap: 20px;
        @include tablet-and-phone {
            flex-direction: column;
            width: 100%;

            gap: 10px;
        }
        img {
            @include tablet-and-phone {
                display: none;
            }
        }
    }
    &__groups {
        display: grid;
        width: 100%;

        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        gap: 20px;
        .button {
            width: 100%;
            min-width: 0;

            border: none;
        }
    }

    &__group {
        font-weight: 600;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: 16px;

        color: $green-900;
        border-radius: 20px;
        background-color: $gold-100;
        &--logged {
            color: $gray-100;
            background-color: $green-400;
            box-shadow: 0 0 10px 1px $gold-500;
        }

        &__copy {
            font-weight: bold;

            width: 100%;
            padding: 10px;
            transition: 0.2s;
            text-align: center;

            color: inherit;

            &:hover {
                color: $gold-500;
            }
        }
        &__name {
            font-family: 'Nova Square';
            font-size: 24px;
            font-weight: 600;

            overflow: hidden;
            width: 100%;
            margin: 0;
            margin-bottom: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        &__logged {
            font-size: 12px;
            font-weight: 600;

            margin: 0;
            margin-top: 8px;
            text-transform: uppercase;
        }

        &__content {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 20px 0;

            gap: 20px;
        }

        &__rankImg {
            width: 100px;
        }

        &__score {
            display: flex;
            align-items: center;
        }
        &__role,
        &__date,
        &__score,
        &__rank {
            margin: 0;
        }

        &__rank {
            font-family: 'Oleo Script Swash Caps';
            font-size: 24px;

            align-self: center;

            color: $yellow-500;
        }

        &__col {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;

            gap: 10px;
            &--rank {
                gap: 0;
            }
        }
        &__buttons {
            display: flex;
            align-items: center;
            justify-content: center;

            gap: 20px;
        }
    }
    &__column {
        display: flex;
        flex: 50% 1 0;
        flex-direction: column;
        justify-content: center;
        text-align: left;
    }
    &__ul {
        list-style: none;

        @include tablet-and-phone {
            padding-left: 0;
        }
    }
    &__columnItemTitle {
        @include tablet-and-phone {
            font-size: 14px;
        }
    }
    // li {
    //     margin: 4px 0;
    // }
    & &__li {
        font-size: 16px;

        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 0 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        &:nth-last-child(1) {
            @include tablet-and-phone {
                margin-bottom: 0;
            }
        }
    }
    .recharts-layer.recharts-cartesian-axis-tick {
        font-size: 12px;
    }

    &__columnItemValue {
        font-size: 20px;
        font-weight: 600;

        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 8px;

        color: $green-700;
    }
    &__leaderboard {
        overflow: hidden;
        min-width: 330px;

        border: 1px solid $gold-500;
        border-radius: 20px;

        &__title {
            font-family: 'Nova Square';
            font-size: 20px;
            font-weight: 600;

            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            padding: 20px;

            color: $gray-100;
            background-color: $gold-500;

            svg {
                margin: 0;
            }
        }
        &__list {
            width: 100%;
            margin: 0;

            gap: 0;
        }

        &__item {
            width: 100%;
            margin: 0;
            padding: 10px;
            text-align: left;
            &:not(:last-child) {
                border-bottom: 1px solid $gold-300;
            }
        }
    }

    &__progress {
        font-weight: bold;

        position: relative;
        display: flex;
        overflow: hidden;
        width: 100%;
        height: 16px;

        color: $gray-900;
        border-radius: 10px;
        background-color: $gray-400;

        &__desc {
            font-size: 16px;

            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            margin: 0;

            color: $gray-800;

            @include tablet-and-phone {
                display: inline-block;
                margin-top: 10px;
            }
        }

        &--fill {
            height: 100%;

            border-radius: 10px;
            background-color: $gold-500;
        }
    }

    &__score {
        font-size: 40px;
        font-weight: bold;

        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        svg {
            width: 53px;
            height: 40px;
        }
    }
    &__col {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        .row {
            align-items: flex-start;

            @include tablet-and-phone {
                align-items: center;
                flex-direction: row;
            }
        }
        & + & {
            flex-grow: 1;
            justify-content: space-between;
        }
    }

    &__rankWrapper {
        width: 100%;
        padding: 20px;

        border: 1px solid $gold-300;
        border-radius: 20px;
        box-shadow: 0 0 4px 0 $gold-500;
    }
    &__imgWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 130px;
        height: 130px;

        border-radius: 20px;
        background-color: $red-700;

        @include tablet-and-phone {
            width: 100px;
            height: 100px;
        }
        img {
            margin-bottom: 10px;
        }
    }
    &__rankDetails {
        font-family: 'Oleo Script Swash Caps';
        font-size: 34px;

        margin: 0;

        color: $gold-500;
    }
    &__rankDetailsScore {
        font-size: 20px;

        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;

        color: $gray-800;
    }
}
