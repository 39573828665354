@import '../../../../styles/theme.scss';
.section {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 100px 0;

    @include mobile {
        padding: 50px 0;
    }

    .row {
        @include tablet-and-phone {
            flex-direction: column;
        }
    }
    &__title {
        font-family: 'Nova Square';
        font-size: 32px;

        margin: 0;
        margin-bottom: 32px;

        @include mobile {
            font-size: 28px;

            margin-bottom: 16px;
        }
    }

    &__desc {
        max-width: 460px;
        margin-bottom: 16px;

        @include mobile {
            margin-top: 0;
            text-align: center;
        }

        &--link {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 5px 0;

            color: currentColor;

            svg {
                width: 18px;
                height: 18px;
                margin-right: 5px;
            }
        }
        // &:nth-last-of-type(1) {
        //     margin-bottom: 50px;
        // }
    }
    &--lightestGreen {
        color: $green-900;
        background-color: $green-100;
        .formPage__formContainer {
            background-color: transparent;
        }
        .formPage__formControl {
            width: 300px;

            background-color: transparent;
        }
    }
    &--lightGreen {
        background-color: $green-500;
    }
    &--gold {
        background-color: $gold-500;
    }
    &__cta {
        margin-top: 70px;

        @include mobile {
            margin-top: 50px;
        }
    }
    &--white {
        color: $green-900;
        background-color: $gray-100;
    }
    &__content {
        display: flex;
        align-items: center;
        flex-direction: column;

        .section__desc {
            text-align: center;
        }
    }
    &__heart {
        width: 120px;
    }
}
