@import '../../styles/theme.scss';

.iconButton {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 80px;

    $self: &;

    &:disabled {
        cursor: not-allowed;

        opacity: 0.2;
    }

    &:hover:not(:disabled) {
        #{$self}__text {
            color: $clubs;
        }

        #{$self}__icon {
            transform: translateY(-5px);
        }
    }

    &__icon {
        margin-bottom: 6px;
        transition: 0.2s;
    }

    &__text {
        font-size: 12px;
        font-weight: bold;
        line-height: 12px;

        margin: 0;
        transition: 0.2s;
        text-align: center;
        text-transform: uppercase;

        color: $dark;
    }
}
