@import '../../../styles/theme.scss';
.lessonItem {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 200px;
    padding: 16px;
    transform: all 0.2s;

    color: $gray-900;
    border: 4px solid $gold-500;
    border-radius: 40px;
    background-color: $gray-100;

    @include mobile {
        width: 150px;
        height: 150px;
    }
    &:hover {
        box-shadow: 0 0 15px 0 $gold-500;
    }
    ~ .lessonItem__arrow svg {
        fill: $gray-800;
    }
    &--done:not(.instructor) {
        color: $gray-100;
        border: 4px solid $gold-500;
        background-color: $green-500;

        &:hover {
            background-color: $green-400;
            box-shadow: 0 0 15px 0 $gold-500;
        }
        ~ .lessonItem__arrow svg {
            fill: $gray-100;
        }
    }

    &--unavailable:not(.instructor) {
        cursor: not-allowed;
        pointer-events: none;

        color: $gray-800;
        border: 4px solid $gray-500;
        background-color: $gray-300;
        &.instructor {
            cursor: pointer;
            pointer-events: all;
            &:hover {
                box-shadow: 0 0 15px 0 $gold-500;
            }
        }

        &::after,
        &::before {
            background-color: $gray-500 !important;
        }

        ~ .lessonItem__arrow {
            border-color: $gray-500;
            svg {
                fill: $gray-800;
            }
        }
    }
    &::after,
    &::before {
        position: absolute;
        top: calc(50% + 2px);
        right: -48px;
        width: 48px;
        height: 4px;
        content: '';
        cursor: default;
        pointer-events: none;

        background-color: $gold-500;
    }

    &::before {
        right: auto;
        left: -48px;
    }

    &.done {
        color: $gray-100;
        background-color: $green-500;
    }
    &.inactive {
        color: $gray-400;
        border-color: $gray-500;
        background-color: $gray-300;
    }

    &__title {
        font-size: 20px;
        font-weight: 400;
        line-height: 38px;

        text-align: center;

        @include tablet-and-phone {
            font-size: 15px;
            line-height: 20px;
        }
    }

    &--add {
        border: 4px dashed $gold-500;
        background-color: transparent;
    }

    &__wrapper {
        position: relative;

        &:nth-child(1) {
            .lessonItem::before {
                display: none;
            }
        }
    }

    &__arrow {
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 50px;
        transform: all 0.2s;
        pointer-events: all;

        opacity: 1;
        border: 2px solid $gold-500;
        border-radius: 10px 0 40px 0;
        svg {
            fill: $gold-500;
        }
        &:hover {
            background-color: $gold-500;
        }
        &--left {
            right: auto;
            left: 0;

            border-radius: 0 10px 0 40px;
        }
    }
}
