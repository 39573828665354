.explanation__wrapper {
    display: flex;

    align-items: center;
    justify-content: center;
}

.explanation__item {
    display: flex;
    align-items: center;
}
