@import '../../styles/theme.scss';

.test-sequence {
    display: grid;
    max-width: 200px;
    margin: auto;

    grid-template-columns: repeat(4, 1fr);
}

.seqence-wrapper {
    flex: 50% 0 0;
}

.bids-space {
    position: relative;
    width: 300px;
    height: 240px;
    margin: 30px auto;

    border: 4px solid white;
    border-radius: 16px;
}

.bids-space.v-both,
.bids-space.v_both {
    border: 4px solid var(--hearts);
}
.bids-space.v-none,
.bids-space.v_none {
    border: 4px solid var(--clubs);
}
.bids-space.v-ns-red,
.bids-space.v-ns_red {
    border: 4px solid var(--clubs);
    border-top-color: var(--hearts);
    border-bottom-color: var(--hearts);
}
.bids-space.v-ew-red,
.bids-space.v-ew_red {
    border: 4px solid var(--clubs);
    border-right-color: var(--hearts);
    border-left-color: var(--hearts);
}

.sequence-item {
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
    line-height: normal;

    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    /* align-items: flex-start; */
    justify-content: flex-start;
    box-sizing: border-box;
    width: 69px;
    height: 52px;
    margin: 0;
    /* transform: translate(-50%, -50%); */
    padding: 4px 0 4px 4px;
    transition: opacity 0.4s 2s;
    text-align: left;
    text-align: center;

    opacity: 0;
    color: var(--dark);
    border: none;
    /* flex-direction: column; */
    border: 1px solid var(--spades);
    border-radius: 8px;
    background-color: #fff;
    /* box-shadow: -2px 4px 4px 0 #0006; */

    &.shouldAlert-true {
        &::after {
            font-size: 12px;

            position: absolute;
            top: -1px;
            right: 5px;
            padding: 5px;
            content: 'A';

            color: #fff;
            border-radius: 0 0 3px 3px;
            background-color: $blue-700;
        }
    }
}

.sequence-item--explanation {
    position: relative;
    top: 0;
    left: 0;
    display: inline-flex;
    margin-right: 10px;
    margin-left: 10px;
    vertical-align: middle;
}

.sequence__number {
    line-height: 20px;

    width: 10px;
    margin-top: -3px;
    margin-right: 2px;
}

.sequence__special {
    font-size: 20px;
    font-weight: 600;

    padding-left: 3px;
}

.sequence__bid-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.sequence__bid-wrapper img {
    margin: 3px 0;
}

.sequence__bid-wrapper.sequence__bid-wrapper--left {
    align-items: flex-start;
}

.sequence-item--DIAMONDS {
    color: var(--diamonds);
    border: 1px solid var(--diamonds);
    background-color: var(--fadedD);
}

.sequence-item--CLUBS {
    color: var(--clubs);
    border: 1px solid var(--clubs);
    background-color: var(--fadedC);
}

.sequence-item--HEARTS {
    color: var(--hearts);
    border: 1px solid var(--hearts);
    background-color: var(--fadedH);
}

.sequence-item--SPADES {
    color: var(--spades);
    border: 1px solid var(--spades);
    background-color: var(--fadedS);
}

.N-bid {
    top: 8px;
    left: calc(var(--bidInitialStart) + var(--bidSpace));
}

.sequence-item-second.N-bid,
.sequence-item-depth-1.N-bid {
    z-index: 2;
    left: calc(var(--bidInitialStart) + var(--bidSpace) * 2);
}

.sequence-item-third.N-bid,
.sequence-item-depth-2.N-bid {
    z-index: 2;
    left: calc(var(--bidInitialStart) + var(--bidSpace) * 3);
}

.sequence-item-fourth.N-bid,
.sequence-item-depth-3.N-bid {
    z-index: 2;
    left: calc(var(--bidInitialStart) + var(--bidSpace) * 4);
}

.sequence-item-fifth.N-bid,
.sequence-item-depth-4.N-bid {
    z-index: 2;
    left: calc(var(--bidInitialStart) + var(--bidSpace) * 5);
}

.sequence-item-sixth.N-bid,
.sequence-item-depth-5.N-bid {
    z-index: 2;
    left: calc(var(--bidInitialStart) + var(--bidSpace) * 6);
}

.S-bid {
    top: calc(100% - 60px);
    left: calc(var(--bidInitialStart) + var(--bidSpace));
}

.sequence-item-second.S-bid,
.sequence-item-depth-1.S-bid {
    z-index: 2;
    left: calc(var(--bidInitialStart) + var(--bidSpace) * 2);
}

.sequence-item-third.S-bid,
.sequence-item-depth-2.S-bid {
    z-index: 2;
    left: calc(var(--bidInitialStart) + var(--bidSpace) * 3);
}

.sequence-item-fourth.S-bid,
.sequence-item-depth-3.S-bid {
    z-index: 2;
    left: calc(var(--bidInitialStart) + var(--bidSpace) * 4);
}

.sequence-item-fifth.S-bid,
.sequence-item-depth-4.S-bid {
    z-index: 2;
    left: calc(var(--bidInitialStart) + var(--bidSpace) * 5);
}

.sequence-item-sixth.S-bid,
.sequence-item-depth-5.S-bid {
    z-index: 2;
    left: calc(var(--bidInitialStart) + var(--bidSpace) * 6);
}

.E-bid {
    top: calc(var(--bidInitialStart) + var(--bidSpace));
    /* left: calc(100% - 8px);
	transform: translate(-100%, -50%); */
    right: 0;
    left: auto;
    transform: rotate(90deg);
}

.sequence-item-second.E-bid,
.sequence-item-depth-1.E-bid {
    /* transform: translate(-50%, -50%); */
    z-index: 2;
    top: calc(var(--bidInitialStart) + var(--bidSpace) * 2);
}

.sequence-item-third.E-bid,
.sequence-item-depth-2.E-bid {
    z-index: 2;
    /* transform: translate(0%, -50%); */
    top: calc(var(--bidInitialStart) + var(--bidSpace) * 3);
}

.sequence-item-fourth.E-bid,
.sequence-item-depth-3.E-bid {
    z-index: 2;
    /* transform: translate(50%, -50%); */
    top: calc(var(--bidInitialStart) + var(--bidSpace) * 4);
}

.sequence-item-fifth.E-bid,
.sequence-item-depth-4.E-bid {
    z-index: 2;
    /* transform: translate(100%, -50%); */
    top: calc(var(--bidInitialStart) + var(--bidSpace) * 5);
}

.sequence-item-sixth.E-bid,
.sequence-item-depth-5.E-bid {
    z-index: 2;
    /* transform: translate(150%, -50%); */
    top: calc(var(--bidInitialStart) + var(--bidSpace) * 6);
}

.W-bid {
    top: auto;
    right: auto;
    bottom: calc(var(--bidInitialStart) + var(--bidSpace));
    left: 0;
    transform: rotate(-90deg);
}

.sequence-item-second.W-bid,
.sequence-item-depth-1.W-bid {
    z-index: 2;
    bottom: calc(var(--bidInitialStart) + var(--bidSpace) * 2);
}

.sequence-item-third.W-bid,
.sequence-item-depth-2.W-bid {
    z-index: 2;
    bottom: calc(var(--bidInitialStart) + var(--bidSpace) * 3);
}

.sequence-item-fourth.W-bid,
.sequence-item-depth-3.W-bid {
    z-index: 2;
    bottom: calc(var(--bidInitialStart) + var(--bidSpace) * 4);
}

.sequence-item-fifth.W-bid,
.sequence-item-depth-4.W-bid {
    z-index: 2;
    bottom: calc(var(--bidInitialStart) + var(--bidSpace) * 5);
}

.sequence-item-sixth.W-bid,
.sequence-item-depth-5.W-bid {
    z-index: 2;
    bottom: calc(var(--bidInitialStart) + var(--bidSpace) * 6);
}

.sequence-item--PASS {
    font-weight: 600;

    color: #f5e9e2;
    border: 1px solid var(--dark);
    background-color: var(--clubs);
}
.sequence-item--DOUBLE {
    color: #f5e9e2;
    border: 1px solid var(--dark);
    background-color: var(--hearts);
}

.sequence-item--REDOUBLE {
    color: #f5e9e2;
    border: 1px solid var(--dark);
    background-color: var(--rktr);
}

.seat {
    font-size: 20px;
    font-weight: 600;

    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    min-width: 30px;
    margin: 0;
    transform: translate(-50%, -50%);
}

.seat-N {
    top: 0;
    transform: translate(-50%, -120%);
}

.seat-S {
    top: 100%;
    transform: translate(-50%, 20%);
}

.seat-W {
    left: 0;
    transform: translate(-130%, -50%);
}

.seat-E {
    left: 100%;
    transform: translate(20%, -50%);
}

.sequence-item-show {
    transition: opacity 1s 0.5s;

    opacity: 1;
}

.dealer {
    font-size: 12px;
    font-weight: 600;

    height: 0;
    /* position: absolute; */
    /* top: 2px; */
    margin: 0;
    /* transition: .2s; */

    opacity: 0;
}

.dealer.show {
    height: auto;

    opacity: 1;
}

/* .dealer-bottom-right {
    top: 20px;
    left: -6px;
}

.dealer-bottom-left {
    top: 20px;
    left: -9px;
} */

.S-bid.sequence-item-show:not(.emptyBid) {
    transition: opacity 0.4s 0s;
}

.W-bid.sequence-item-show {
    transition: opacity 0.4s 0.5s;
}

.N-bid.sequence-item-show {
    transition: opacity 0.4s 1s;
}

.E-bid.sequence-item-show {
    transition: opacity 0.4s 1.5s;
}

.glow {
    animation: glow 1s forwards;
}

.S-bid.sequence-item-show.emptyBid {
    transition: opacity 0.4s 2s;
}
.S-bid.sequence-item-show.emptyBid:nth-child(1) {
    transition: opacity 0.4s 0s;
}

.current-depth-1 .S-bid,
.current-depth-2 .S-bid,
.current-depth-3 .S-bid,
.current-depth-4 .S-bid,
.current-depth-5 .S-bid {
    animation: pulse-glow 1s forwards;
}

@keyframes pulse-glow {
    0% {
        box-shadow: -2px 4px 4px 0 rgba(196, 130, 8, 0.7);
    }

    70% {
        box-shadow: 0 0 0 15px rgba(196, 130, 8, 0);
    }

    85% {
        box-shadow: 0 0 0 4px rgba(196, 130, 8, 0);
    }

    100% {
        box-shadow: -2px 4px 4px 0 rgba(0, 0, 0, 0.4);
    }
}
