@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&family=Oleo+Script+Swash+Caps&display=swap');
@import url('https://fonts.cdnfonts.com/css/nova-square');

body {
    font-family: 'Open Sans', sans-serif;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    margin: 0;
    text-align: center;

    color: var(--light);
    background-color: var(--dark);

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
    --hearts: #a71127;
    --rktr: #00538f;
    --clubs: #10530a;
    --diamonds: #c48208;
    --spades: #231f1f;
    --fadedD: #ffebc8;
    --fadedC: #e4fbe2;
    --fadedS: #e1d9d9;
    --fadedH: #ffe8eb;
    --light: #f2ecfe;
    /* --dark: #110e1b; */
    --dark: #040f01;
    --dark2: #040f01;
    /* --dark2: #130103; */
    /* --dark: #161925; */
    /* --dark: #0D0221; */
    --brown: #af3800;
    --bidSpace: 25px;
    --bidInitialStart: calc(50% - 80px);
    --logoAnimTime: 1.5s;
}

body::-webkit-scrollbar {
    display: none;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.big {
    font-size: 20px;
    font-weight: 600;
}

.small {
    font-size: 12px;
}

.App-header {
    font-size: calc(10px + 2vmin);

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;

    color: white;
    background-color: #282c34;
}

.App-link {
    color: #61dafb;
}

/* .cards {
	display: flex;
	flex-direction: row;
} */

/*
.cardImage.front {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}

.cardImageBack__wrapper {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
} */

.red {
    color: 'red';
}

.bid {
    min-height: 32px;
    margin-top: 20px;
    margin-bottom: 20px;
}
/* 
.bid h2 {
    margin: 0;
} */

.interface {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 500px;
    margin: auto;
}
.small {
    /* color: #bbb; */
    font-size: 12px;
}

.title {
    padding: 0 8px;
}

.version {
    font-size: 12px;

    cursor: auto;
    text-align: right;

    border: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    padding: 0;

    border: 0;
}

button {
    font-family: 'Open Sans';
    font-size: 1em;
    font-weight: normal;
    line-height: 1.42rem;

    margin: 0;
    padding: 0;
    border-spacing: 0;
    list-style: none outside none;
    cursor: pointer;
    text-align: left;
    text-decoration: none;
    text-indent: 0;

    color: var(--light);
    border: medium none;
    background: none repeat scroll 0 0 transparent;
}

.test-input {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-family: inherit;
    font-size: 1em;

    margin: 0 10px;

    border: none;
    border-radius: 2px;
}

.redo-item {
    margin: 5px;

    color: black;
}

.redo-items {
    display: inline-block;
}

.redo-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;

    @media only screen and (min-width: 600px) {
        align-items: baseline;
        flex-direction: row;
    }
}

.redo-title {
    font-weight: 600;

    margin: 0;

    @media only screen and (max-width: 600px) {
        font-size: 20px;
        font-weight: 600;

        margin-bottom: 8px;
    }
}

.stats-item {
    margin: 5px auto;
}

.points {
    margin-bottom: 0;
    letter-spacing: 1px;

    color: var(--diamonds);
}

.gray {
    font-weight: 400;

    color: gray;
}

.explanation {
    padding: 0 10px;
}

.seq-bids {
    display: flex;
    align-items: center;
    flex-direction: column;

    @media screen and (min-width: 600px) {
        flex-direction: row;
    }
}

.sectionAbout {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
}
.sectionAbout--dark2 {
    background-color: var(--dark2);
}

.subsection-title {
    font-size: 16px;
    font-weight: 600;

    margin-bottom: 20px;
    letter-spacing: 2px;
    text-transform: uppercase;

    color: var(--diamonds);
}

.row {
    display: flex;
    width: 100%;

    gap: 20px;
    @media screen and (max-width: 600px) {
        flex-direction: column;
    }

    &.justify--between {
        justify-content: space-between;
    }
    &.justify--center {
        justify-content: center;
    }
}

.row--reverse {
    flex-direction: row-reverse;
    @media screen and (max-width: 600px) {
        flex-direction: column;
    }
}

.col {
    display: flex;
    align-items: center;
    flex: 1 1 50%;
    flex-direction: column;
    justify-content: center;
    max-width: 50%;

    @media screen and (max-width: 600px) {
        flex: 1 1 100%;
        max-width: 100%;
    }
}
.lessons,
.imageSection--red-noPb,
.header__item,
.custom-card {
    div > svg:not(.MuiSvgIcon-root) {
        width: 100%;
        height: 100%;

        object-fit: contain;
    }
}
