@mixin phone-only {
    @media (max-width: 599px) {
        @content;
    }
}
@mixin mobile {
    @media (max-width: 768px) {
        @content;
    }
}

@mixin tablet-and-phone {
    @media (max-width: 1200px) {
        @content;
    }
}
@mixin small-screens {
    @media (max-width: 1400px) {
        @content;
    }
}

@mixin desktop-up {
    @media (min-width: 1200px) {
        @content;
    }
}

.page {
    font-size: 14px;

    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

body {
    font-size: 14px;
}

#root {
    width: 100%;
}

.mt-3 {
    margin-top: 16px;
}

* {
    box-sizing: border-box;
}

.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 1400px;
    padding: 0 20px;
}

.wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    &--spaced {
        justify-content: space-between;
    }
}

.link {
    color: inherit;
}

.noMt {
    margin-top: 0;
}
.noMb {
    margin-bottom: 0;
}
