@import '../../styles/theme.scss';

.statistics {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;

    color: $green-900;
    background-color: $green-100;

    .request {
        justify-content: center;

        @include tablet-and-phone {
            flex-direction: row;

            gap: 10px;
        }
    }

    .content .container {
        display: flex;
        align-items: stretch;
        flex-direction: column;
        justify-content: center;
        margin: auto;
        padding-top: 100px;

        gap: 50px;
    }
    &__paper {
        padding: 25px;
    }
    svg {
        margin: 0 4px;
    }
    &__tooltip {
        padding: 10px;

        border-radius: 10px;
        background-color: $gray-100;
        box-shadow: 0 0 5px 0 #0006;
    }

    &__label {
        font-weight: bold;

        color: $gold-500;
    }
}
