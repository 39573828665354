@import '../../styles/theme.scss';
.cards {
    display: flex;
    height: auto;

    @media only screen and (min-width: 700px) {
        display: block;
        overflow: visible;
        box-sizing: border-box;
        width: 100%;
        margin: 0;
        margin-bottom: 20px;
        margin-left: 40px;

        border-radius: 0;
    }
    @media only screen and (min-width: 900px) {
        margin-left: 50px;
    }
    @media only screen and (min-width: 1200px) {
        margin-left: 75px;
    }
    &__title {
        font-size: 20px;
        font-weight: 600;

        margin: 0;
    }

    &-wrapper {
        width: 100%;
        margin: 40px auto 0;
        .game & {
            overflow: hidden;
        }

        @media only screen and (min-width: 600px) {
            padding: 0;
        }
    }
    &__testName {
        font-weight: 400;

        color: transparentize($color: $gray-300, $amount: 0.3);
    }
}
