@import '../../styles/theme.scss';

.footer {
    font-size: 12px;

    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;

    color: var(--light);
    background-color: $olive-500;

    &__top {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 50px 20px;
    }

    &__row {
        display: flex;
        align-items: stretch;
        justify-content: space-between;

        @media only screen and (max-width: 600px) {
            flex-direction: column;
        }
    }

    &__desc {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 5px 0;

        color: white;

        svg {
            width: 18px;
            height: 18px;
            margin-right: 5px;
        }
    }

    &__col {
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        &--first {
            justify-content: space-between;
        }
    }

    &__title {
        font-weight: bold;

        text-transform: uppercase;
    }

    &__link {
        transition: 0.2s;
        text-decoration: none;

        color: var(--diamonds);

        &:hover {
            color: white;
        }
    }

    &__bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 15px;

        background-color: $olive-900;

        &-text {
            display: flex;
            justify-content: center;
            margin: 0;

            gap: 4px;

            span {
                display: flex;
                align-items: center;
            }
        }
    }

    &__version {
        margin: 0;
    }
}
