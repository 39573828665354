@import '../../styles/theme.scss';

.biddingUI {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 0 20px;

    @include mobile {
        margin: 0;
    }

    &__title {
        font-family: 'Nova Square';
        font-size: 20px;

        margin-bottom: 16px;
    }
    &__alertCheck {
        font-size: 30px;

        position: relative;
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-right: 3px;

        border: 1px solid currentColor;
        border-radius: 2px;

        @include mobile {
            font-size: 20px;

            margin-right: 3px;
        }
        .biddingUI_button-alert--on &::after {
            font-size: 20px;

            position: absolute;
            top: -6px;
            left: 0;
            display: block;
            content: '✓';

            color: currentColor;
        }
    }
}

.biddingUI-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.row-numbers {
    margin-bottom: 5px;
}

.suits {
    margin-bottom: 20px;
}

.bid__correct {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    animation: none;
    letter-spacing: 2px;

    opacity: 0;
    color: var(--diamonds);
}

.bid {
    position: relative;
    flex: 50% 0 0;
}

.current-depth-1 + .bid .bid__correct,
.current-depth-3 + .bid .bid__correct,
.current-depth-5 + .bid .bid__correct {
    animation: show 3s 1;
}

.current-depth-2 + .bid .bid__correct,
.current-depth-4 + .bid .bid__correct,
.current-depth-6 + .bid .bid__correct {
    animation: show1 3s 1;
}

@keyframes show {
    0% {
        opacity: 0;
    }

    30% {
        transform: translateX(-50%) scale(1.2);

        opacity: 1;
    }

    100% {
        transform: translateX(-50%) scale(1.2);

        opacity: 0;
    }
}

/* its really stupid solution, but it works...
https://stackoverflow.com/questions/38018983/how-to-run-the-same-animation-when-switching-classes-without-javascript */

@keyframes show1 {
    0% {
        opacity: 0;
    }

    70% {
        transform: translateX(-50%) scale(1.2);

        opacity: 1;
    }

    100% {
        transform: translateX(-50%) scale(1.3);

        opacity: 0;
    }
}

.biddingUI_button {
    font-size: 20px;
    font-weight: 700;
    font-style: normal;

    display: inline-flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-width: 50px;
    min-height: 40px;
    margin: 2px 3px !important;
    padding: 5px 10px;
    /* line-height: normal; */
    cursor: pointer;
    transition: 0.2s;
    text-align: center;

    color: var(--dark);
    border: none;
    border: 1px solid currentColor;
    border-radius: 8px;
    background-color: $gray-100;

    svg {
        width: 100%;
        height: 100%;

        object-fit: contain;
    }

    &__icon {
        width: 20px;
    }
}

.biddingUI_button:hover {
    box-shadow: 0 0 5px 1px var(--diamonds);
}

.biddingUI_button:disabled {
    cursor: auto;

    opacity: 0.4;
}

.biddingUI_button:disabled:hover {
    cursor: auto;

    box-shadow: none;
}

.biddingUI_button.chosen {
    color: white;
    border: 1px solid currentColor;
    background-color: var(--diamonds);
    box-shadow: 0 0 5px 1px var(--diamonds);
}

.biddingUI_button.chosen:hover {
    cursor: auto;
}

.biddingUI_button-ktr,
.biddingUI_button-rktr,
.biddingUI_button-pas {
    font-weight: 600;
    line-height: 20px;

    color: var(--light);
    border: none;
}

.biddingUI_button-ktr {
    background-color: var(--hearts);
}

.biddingUI_button-rktr {
    background-color: var(--rktr);
}

.biddingUI_button-pas {
    background-color: var(--clubs);
}

.biddingUI_button-alert {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: auto;

    color: $gray-400;
    background-color: $blue-700;

    &--on {
        color: $gray-100;
        // transform: translate(0, -5px);
        // color: $gray-100;
        border: 1px solid currentColor;
        background-color: $blue-700;
        box-shadow: 0 0 5px 1px currentColor;
    }
}

.button-text {
    margin: 0;
}

.small {
    font-size: 9px;
}

.tested {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 32px;
}

.tested > div {
    flex: 50% 0 0;
}

.tested__text {
    font-size: 12px;

    letter-spacing: 1px;

    @include mobile {
        max-width: 120px;
        margin-right: auto;
        margin-left: auto;
    }
}

.tested__comment {
    @include mobile {
        max-width: none;
        margin-right: auto;
        margin-left: auto;
    }
}

.tested__result {
    font-size: 26px;
    font-weight: 800;

    max-width: 320px;
    margin: 10px auto;
}

.tested__result--0 {
    color: var(--hearts);
}

.tested__result--1 {
    color: var(--clubs);
}
