@import '../../../../styles/theme.scss';

.smallCardsInput {
    $self: &;

    &__wrapper {
        display: grid;
        margin-top: 10px;

        border-top: 1px solid $dark;
        border-left: 1px solid $dark;
        background-color: $white;

        grid-template-columns: repeat(13, 1fr);

        &--max {
            border-top: 1px solid $dark;
            border-left: 1px solid $dark;

            #{$self}__card {
                border-right: 1px solid $dark;
                border-bottom: 1px solid $dark;
                background-color: $white;

                &.notActive {
                    cursor: auto;
                }
            }
        }
    }

    &__card {
        font-weight: bold;

        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding: 4px 2px;

        color: $clubs;
        border-right: 1px solid $dark;
        border-bottom: 1px solid $dark;
        // background-color: transparentize($color: $gray, $amount: 0.7);

        &.notActive {
            &::after {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                content: '';

                background-color: transparentize($color: $gray, $amount: 0.2);
            }
        }

        &--hearts {
            color: $hearts;
        }

        &--diamonds {
            color: $diamonds;
        }

        &--spades {
            color: $spades;
        }
    }

    &__suit {
        width: 15px;
        height: 15px;
    }

    &__error {
        font-size: 12px;
        font-weight: bold;

        margin-top: 5px;

        color: $hearts;
    }
}
