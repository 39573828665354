@import '../../../../styles/theme.scss';

.addItem {
    position: relative;

    $self: &;

    &.focused {
        #{$self}__wrapper {
            border-color: $diamonds;
            outline: 3px solid $diamonds;
            // &::after {
            //     font-size: 16px;
            //     font-weight: 700;

            //     position: absolute;
            //     top: 0;
            //     right: 0;
            //     bottom: 0;
            //     left: 0;
            //     display: flex;
            //     align-items: center;
            //     justify-content: center;
            //     content: '?';

            //     color: $dark;
            //     border-radius: 7px;
            //     background-color: $diamonds;
            // }
        }
    }
    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: 47px;
        height: 35px;
        margin: 0;
        transition: transform .2s .02s;

        border: 1px solid $white;
        border-radius: 8px;

        &:hover {
            transform: translateY(-5px);
        }
    }
}
