.card {
    width: calc(100% / 13);
    height: calc(((100vw - 20px) / 13) * 3);

    @media only screen and (min-width: 700px) {
        position: relative;
        z-index: 1;
        display: inline-block;
        width: 120px;
        height: 200px;
        margin-left: -80px;

        border-radius: 8px;
        background-color: var(--light);
    }
    @media only screen and (min-width: 900px) {
        width: 150px;
        height: 230px;
        margin-left: -100px;
    }

    @media only screen and (min-width: 1200px) {
        width: 200px;
        height: 300px;
        margin-left: -150px;

        border-radius: 20px;
    }
}

.custom-card {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 6px;

    color: var(--dark);
    border: 1px solid var(--dark);
    border-radius: 0;
    background-color: var(--light);
    box-shadow: -2px 4px 4px 0 rgba(0, 0, 0, 0.212);

    @media only screen and (min-width: 700px) {
        padding: 8px;

        border-radius: 8px;
    }

    @media only screen and (min-width: 1200px) {
        padding: 6px 12px;

        border-radius: 20px;
    }
}

.custom-card--D {
    color: var(--diamonds);
}

.custom-card--S {
    color: var(--spades);
}

.custom-card--H {
    color: var(--hearts);
}

.custom-card--C {
    color: var(--clubs);
}

.custom-card__number {
    font-size: 16px;
    font-weight: 800;

    z-index: -0;
    margin-top: 0;
    margin-bottom: 10px;

    @media only screen and (min-width: 700px) {
        font-size: 20px;
    }

    @media only screen and (min-width: 900px) {
        font-size: 32px;
    }
}

.custom-card__suit {
    z-index: -0;
    width: 16px;
    height: auto;

    @media only screen and (min-width: 700px) {
        width: 20px;
    }

    @media only screen and (min-width: 900px) {
        width: 30px;
    }
}

.custom-card__bottom {
    z-index: -0;
    align-self: flex-end;
}

.custom-card__bottom,
.custom-card__top {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.custom-card__bottom {
    display: none;
    transform: rotate(180deg);

    @media only screen and (min-width: 700px) {
        display: block;
    }
}

.custom-card__middle {
    z-index: -0;
    display: none;
    align-self: center;

    @media only screen and (min-width: 700px) {
        display: block;
    }
}

.custom-card__middle__number {
    display: none;
    @media only screen and (min-width: 700px) {
        font-size: 50px;
        line-height: 20px;

        display: block;

        opacity: 0.4;
    }
    @media only screen and (min-width: 1200px) {
        font-size: 100px;
        line-height: 30px;
    }
}
