@import '../../../styles/theme.scss';
.ck .ck-widget.ck-widget_with-selection-handle {
    margin-left: 0;
}
.singleLesson {
    &__wrapper {
        width: 100%;
        text-align: left;

        li {
            margin-bottom: 2px;
            &:nth-last-child(1) {
                margin-bottom: 0;
            }
        }

        figure {
            margin-left: 0;
        }
        td {
            font-size: 14px;

            padding: 3px 8px;
            text-align: center;
        }
        table {
            font-size: 20px;

            border: 1px solid $gray-500;
            border-radius: 5px;
        }
    }

    &__button {
        font-size: 16px;
        font-weight: bold;

        padding: 12px 24px;
        transition: 0.2s;
        transition: 0.2s;
        text-transform: uppercase;

        color: $green-900;
        border: 1px solid $green-300;
        border-radius: 8px;
        background-color: $gray-100;

        &--FULL_SUCCESS {
            color: $gray-100;
            background-color: $green-700;
        }
        &--HALF_SUCCESS {
            background-color: $green-300;
        }
        &--FAILED {
            color: $gray-100;
            background-color: $red-500;
        }

        &--active {
            color: $gray-100;
            background-color: $gold-500;
        }

        &:hover {
            box-shadow: 0 0 5px 0 $gold-500;
        }
    }
    &__testList {
        display: flex;
        align-self: flex-start;
        flex-wrap: wrap;

        gap: 20px;
        .singleLesson__title {
            @include mobile {
                width: 100%;
            }
        }
    }
}
