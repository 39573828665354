@import '../../styles/theme.scss';

$sec-hero: 250px;
$third-hero: 330px;

.home {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: center;

    background-color: $green-900;

    .section__hero {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        &-wrapper {
            position: relative;
            padding: 100px 20px 200px;

            @include phone-only {
                display: flex;
                flex-direction: column-reverse;
                padding: 0 20px 50px;
            }
        }

        &__logo {
            z-index: 1;
            margin-bottom: 20px;
            text-align: center;
        }
        &__bg {
            position: absolute;
            z-index: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: calc(100% + 110px);
            pointer-events: none;

            object-fit: cover;
        }

        .section__desc {
            font-size: 30px;

            margin-top: 50px;

            @include phone-only {
                font-size: 20px;

                margin: 0;
                margin-bottom: 40px;
            }

            &--licytapka {
                font-family: 'Oleo Script Swash Caps';
                font-size: 37px;

                padding-right: 8px;

                @include phone-only {
                    font-size: 26px;

                    padding-right: 4px;
                }
            }
        }
    }
    &__hero {
        z-index: 1;

        @include phone-only {
            display: flex;
            flex-direction: column;
            padding: 50px 0 30px;
        }

        &__button {
            margin-top: 48px;

            @include phone-only {
                margin-top: 30px;
            }
        }
    }

    &__hero--first,
    &__hero--sec,
    &__hero--third {
        font-size: 70px;

        display: inline-block;
        overflow: hidden;
        white-space: nowrap;

        @include phone-only {
            font-size: 40px;
        }
    }

    &__hero--first {
        font-weight: 400;

        animation: opacityAnimMobile 6s forwards;

        @include desktop-up {
            animation: opacityAnim 7s forwards;
        }
    }

    &__hero--third span {
        font-weight: 400;

        @include desktop-up {
            margin-left: -$third-hero;
            animation: slideInMore 7s forwards;
        }
    }

    &__hero--sec span {
        font-weight: 400;

        @include desktop-up {
            margin-left: -$sec-hero;
            animation: slidein 7s forwards;
        }
    }

    &__hero--sec {
        animation: opacityAnimSec 6s forwards;

        @include desktop-up {
            width: 0;
            animation: reveal 7s forwards;
        }
    }

    &__hero--third {
        animation: opacityAnimThird 6s forwards;

        @include desktop-up {
            width: 0;
            animation: revealMore 7s forwards;
        }
    }

    &__demo-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        background-color: $green-500;

        @include mobile {
            flex-direction: column;

            gap: 50px;
        }
        .cards-wrapper {
            transform: scale(0.8);
            transform-origin: bottom;

            @include mobile {
                transform: scale(1);
            }
        }
    }

    &__demo {
        &-content {
            max-width: 100%;
        }
        &-image {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 50px;

            @include mobile {
                margin-right: 0;
            }
            img {
                width: 420px;
                max-width: 100%;

                @include mobile {
                    width: 100%;
                }
            }
        }
    }

    &__demo-overlay {
        position: absolute;
        z-index: 10;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        cursor: pointer;
        transition: 0.2s;

        opacity: 0;
        opacity: 0;
        border-radius: 30px;
        background-color: $green-900;

        &--content {
            font-size: 50px;

            position: absolute;
            z-index: 10;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            cursor: pointer;
            transition: 0.2s;
            pointer-events: none;

            opacity: 0;
            border-radius: 30px;
        }
    }

    &__demo {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 100vw;
        min-height: 660px;
        transform: scale(0.8);

        border: 10px solid var(--diamonds);
        border-radius: 30px;

        @include mobile {
            transform: scale(1);

            border: 5px solid var(--diamonds);
        }

        button {
            pointer-events: none;
        }

        .card {
            max-width: 21vw;
        }
    }

    &__desc {
        background-color: var(--diamonds);
    }

    &__section {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 100vw;
        padding: 100px;

        @include phone-only {
            padding: 30px;
        }

        &--bids {
            overflow: hidden;
            width: 100vw;
            padding: 50px 0;

            color: $green-900;
            background-color: $green-100;
        }

        &--dark2 {
            background-color: var(--dark2);
        }

        &--dark {
            background-color: var(--dark);
        }

        &--red {
            background-color: var(--hearts);
        }
    }

    &__logo {
        width: 250px;

        &-desc {
            font-family: 'Oleo Script Swash Caps';
            font-size: 20px;
            font-weight: 100;

            margin-top: 0;
            letter-spacing: 3px;
        }
    }

    &__flying-bids {
        display: flex;
        margin: 50px 0;
        animation: flying linear 60s infinite alternate;

        &--left {
            float: right;
            animation: flying-left linear 60s infinite alternate;
        }
    }

    &__flying-bid {
        position: relative;
        left: 0;
        flex-grow: 1;
        min-width: 70px;
        margin: 8px;
        cursor: auto;
    }

    &__card {
        width: 400px;
        height: 250px;

        background-color: transparent;

        perspective: 1000px;
        @include phone-only {
            width: 250px;
            height: 180px;
        }

        p {
            margin: 0;
        }
    }

    &__card-inner {
        position: relative;
        top: 0;
        width: 100%;
        height: 100%;
        transition: transform 0.8s;
        text-align: center;

        transform-style: preserve-3d;
        -webkit-backface-visibility: hidden;
    }

    &__card:hover &__card-inner {
        transform: rotateY(180deg);
    }

    &__card-front,
    &__card-back {
        font-size: 20px;

        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        border: 2px solid white;

        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    &__card-front {
        font-size: 60px;

        color: white;
        border-radius: 10px;
        background-color: var(--hearts);
    }

    &__card-back {
        font-weight: bold;

        transform: rotateY(180deg);
        text-transform: uppercase;

        color: white;
        border-radius: 10px;
        background-color: var(--hearts);
    }

    &__backoffice {
        max-width: 700px;

        @include phone-only {
            max-width: 90vw;
        }
    }

    &__link {
        transition: 0.2s;
        text-decoration: none;

        color: white;
    }

    &__link:hover {
        color: white;
    }

    .bid {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 350px;
    }

    &__crowns {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin: 30px 0;

        gap: 30px;
        @include phone-only {
            align-items: center;
            flex-direction: column;
        }

        img {
            flex: 0 1 33%;
            max-width: 22%;

            @include phone-only {
                max-width: 50%;
            }

            &:not(:nth-child(1)) {
                @include phone-only {
                    display: none;
                }
            }
        }
    }
}

@keyframes flying {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(-2000px);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes flying-left {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(2000px);
    }

    100% {
        transform: translateX(0);
    }
}

.section-title {
    text-transform: uppercase;
}

@keyframes opacityAnimMobile {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 0;
    }

    20% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}
@keyframes opacityAnim {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 0;
    }

    20% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@keyframes opacityAnimSec {
    0% {
        opacity: 0;
    }

    30% {
        opacity: 0;
    }

    40% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@keyframes opacityAnimThird {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    60% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slidein {
    0% {
        margin-left: -800px;
    }

    20% {
        margin-left: -800px;
    }

    35% {
        margin-left: 0;
    }

    100% {
        margin-left: 0;
    }
}

@keyframes slideInMore {
    0% {
        margin-left: -800px;
    }

    40% {
        margin-left: -800px;
    }

    55% {
        margin-left: 0;
    }

    100% {
        margin-left: 0;
    }
}

@keyframes reveal {
    0% {
        width: 0;

        opacity: 0;
    }

    20% {
        width: 0;

        opacity: 1;
    }

    30% {
        width: $sec-hero;
    }

    90% {
        opacity: 1;
    }

    100% {
        width: $sec-hero;
    }
}

@keyframes revealMore {
    0% {
        width: 0;

        opacity: 0;
    }

    40% {
        width: 0;

        opacity: 1;
    }

    50% {
        width: $third-hero;
    }

    90% {
        opacity: 1;
    }

    100% {
        width: $third-hero;
    }
}
