@import '../../styles/theme.scss';

.page {
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    width: 100%;
    height: 100%;
    min-height: 100vh;

    &--light {
        color: $gray-900;
        background-color: $green-100;
    }

    &__container {
        display: flex;
        align-items: stretch;
        flex-direction: column;
        justify-content: center;
        margin: auto;
        padding: 50px 20px !important;

        gap: 50px;
    }
}
