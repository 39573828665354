@import '../../styles/theme.scss';

.game {
    min-height: 100vh;

    background-color: $green-900;

    > .container {
        flex-grow: 1;
        justify-content: center;
        padding: 10px 0 0;
        transition: 0.2s all;

        @include tablet-and-phone {
            justify-content: space-between;
            min-height: 100dvh;
            padding: 20px 0 0;
        }
    }
    &__levels {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 30px;

        color: $gold-500;

        @include mobile {
            justify-content: center;
        }
    }

    &__level {
        margin: 0 4px;
        padding: 3px 10px;
        text-transform: uppercase;

        color: $gray-700;
        border: 2px solid currentColor;
        border-radius: 16px;

        &.active {
            color: $green-900;
            background-color: $gray-100;
        }
    }

    &__mobileFiller {
        display: none;

        // @include tablet-and-phone {
        //     display: block;
        //     height: 250px;
        // }
    }

    &__title {
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        margin-right: -40px;

        @include tablet-and-phone {
            margin: 0 30px;
        }
        .button {
            position: absolute;
            right: 0;
        }
    }
    &__listButton {
        justify-self: end;
    }
    &__testName {
        flex-grow: 1;
    }

    &__seq {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0 10px;

        @include tablet-and-phone {
            flex-direction: column;
        }
    }
}
