.small-card {
    flex-grow: 0;
    max-width: 20px;
    height: 80px;
}

.first-small-card {
    border-left: 1px solid var(--dark);
}

.small-cards-wrapper {
    margin: 10px auto 0;
    padding: 0 3px;
}

.small-cards {
    display: inline-flex;
    box-sizing: border-box;
    margin-bottom: 20px;

    border-top: 1px solid var(--dark);
    border-bottom: 1px solid var(--dark);
    border-radius: 2px;
    background: red;
}

.custom-small-card {
    position: static;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    width: auto;
    height: 100%;
    padding: 8px 0;

    color: var(--dark);
    border-right: 1px solid var(--dark);
    background-color: #fff;
    box-shadow: -2px 4px 4px 0 rgba(0, 0, 0, 0.212);
    svg {
        width: 100%;
        height: 100%;

        object-fit: contain;
    }

    &--DIAMONDS {
        color: var(--diamonds);
    }

    &--SPADES {
        color: var(--spades);
    }

    &--HEARTS {
        color: var(--hearts);
    }

    &--CLUBS {
        color: var(--clubs);
    }

    &__number {
        font-size: 16px;
        font-weight: 800;

        margin-top: 0;
        margin-bottom: 2px;
    }

    &__suit {
        width: 12px;
        height: auto;
        margin: 2px;
    }

    &__bottom {
        display: none;
        align-self: flex-end;
        transform: rotate(180deg);
    }
    &__top {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    &__middle {
        display: none;
        align-self: center;
    }
}

.small-cardImage {
    display: none;
}
