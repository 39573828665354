@import '../../styles/theme.scss';

.loading {
    &-overlay {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 100vh;

        background-color: $green-900;

        &--blurred {
            background-color: transparent;
            &::before {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                content: '';

                background-color: transparentize($color: $gray-100, $amount: 0.5);

                filter: blur(5px);
            }
        }
    }
    &-img-wrapper {
        position: relative;
        width: 195px;
        height: 70px;
    }

    &-suit {
        position: absolute;
        top: 10px;
        left: 35px;
        width: 14px;

        &--CLUBS,
        &--DIAMONDS,
        &--HEARTS {
            opacity: 0;
        }

        &--CLUBS {
            animation: loadingLogo infinite var(--logoAnimTime);
        }

        &--DIAMONDS {
            animation: loadingLogo infinite var(--logoAnimTime) calc(var(--logoAnimTime) / 3);
        }

        &--HEARTS {
            animation: loadingLogo infinite var(--logoAnimTime) calc(var(--logoAnimTime) * 2 / 3);
        }
    }
}

@keyframes loadingLogo {
    0% {
        opacity: 0;
    }

    33% {
        opacity: 100%;
    }

    60% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}
