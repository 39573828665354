//OLD

$white: #fff;
$hearts: #a71127;
$rktr: #00538f;
$clubs: #10530a;
$diamonds: #c48208;
$spades: #231f1f;
$fadedD: #ffebc8;
$fadedC: #e4fbe2;
$fadedS: #e1d9d9;
$fadedH: #ffe8eb;
$light: #f2ecfe;
$dark: #0a0012;
$dark2: #160029;
$brown: #af3800;
$black: #000;
$gray: #333;

//NEW
$blue-900: #0d0221;
$blue-700: #143266;
$blue-500: #00538f;
$blue-300: #436cb1;
$blue-100: #f2ecfe;

$green-900: #0c2605;
$green-700: #0f5209;
$green-500: #1b4c16;
$green-400: #1b6614;
$green-300: #81cc7a;
$green-100: #e3fae1;

$olive-900: #040f01;
$olive-500: #3e543c;
$olive-300: #a0ad9f;
$olive-100: #ebf7ea;

$gold-700: #875a08;
$gold-500: #c48208;
$gold-300: #ffebc7;
$gold-100: #f4f0ea;

$yellow-500: #f0cb55;
$yellow-300: #ece9da;

$red-900: #4b020c;
$red-700: #880518;
$red-500: #a71127;
$red-100: #ffe7eb;

$brown-500: #77625c;
$brown-300: #e1d8d8;

$gray-1000: #000;
$gray-900: #231f1f;
$gray-800: #777;
$gray-700: #8c8c8c;
$gray-600: #adaca2;
$gray-500: #b9b9b9;
$gray-400: #d3d2d2;
$gray-300: #f5f5f5;
$gray-200: #f9f9f9;
$gray-100: #fff;
