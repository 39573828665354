@import '../../../../styles/theme.scss';
.imageSection {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 100px 0;

    @include mobile {
        padding: 50px 0;
    }

    .button {
        margin-top: 32px;
    }

    &__card {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 550px;
        height: 360px;
        padding-right: 190px;

        border-radius: 20px;
        background-color: $gray-100;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

        @include tablet-and-phone {
            width: calc(100vw - 40px);
            max-width: 700px;
            height: calc((100vw - 40px) * 2 / 3);
            max-height: 450px;
            padding-right: 0;
        }

        &__magda {
            position: absolute;
            right: -30px;
            bottom: 0;
            height: 350px;
            pointer-events: none;

            @include tablet-and-phone {
                display: none;
            }
        }

        svg {
            @include tablet-and-phone {
                margin-bottom: 32px;
            }
        }
    }

    .row {
        @include tablet-and-phone {
            flex-direction: column;
        }
        @include mobile {
            gap: 50px;
        }
    }
    img {
        max-width: 700px;

        border-radius: 20px;

        object-fit: contain;
    }
    &__title {
        font-family: 'Nova Square';
        font-size: 32px;
        font-weight: normal;

        margin: 0;
        margin-bottom: 32px;

        @include mobile {
            font-size: 28px;

            margin-bottom: 16px;
            text-align: center;
        }
    }

    &__desc {
        display: flex;
        align-items: center;
        max-width: 460px;

        color: currentColor;

        @include mobile {
            margin-top: 0;
            text-align: center;
        }
    }
    &--gold {
        // color: $gray-900;
        background-color: $gold-500;
    }
    &--lightestGreen {
        color: $green-900;
        background-color: $green-100;
    }
    &--lighterGreen {
        color: $gray-100;
        background-color: $green-400;
    }
    &--lightGreen {
        background-color: $green-500;
    }
    &--white {
        color: $green-900;
        background-color: $gray-100;

        &-noPb {
            padding-top: 30px;
            padding-bottom: 0;

            color: $green-900;
            background-color: $gray-100;

            + .imageSection {
                padding: 50px 0;
            }
        }
    }
    &--bg {
        font-weight: 700;

        color: $green-900;
        background-color: $gray-100;
        background-image: url('../../../../../public/images/bg-cards.png');
        background-size: cover;

        @include mobile {
            padding: 100px 0;
        }

        .imageSection__desc {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 16px;

            color: $green-900;

            gap: 8px;

            svg {
                margin-bottom: 0;
            }
        }
    }
    &--hero {
        .row {
            align-items: center;
            max-width: 1000px;
        }
        .section__title {
            font-size: 70px;
            font-weight: normal;

            @include mobile {
                font-size: 40px;
            }
        }
        .section__desc {
            font-size: 30px;

            margin-top: 0;

            @include mobile {
                font-size: 20px;
            }
        }
        .imageSection__content {
            padding: 0;
        }
        img {
            max-width: 100%;

            border-radius: 0;
        }
    }

    &--red {
        color: $gray-100;
        background-color: $red-700;

        &-noPb {
            padding-bottom: 0;

            color: $gray-100;
            background-color: $red-700;
            img {
                border-radius: 0;
            }
        }
    }
    &__cta {
        margin-top: 70px;
    }
    &__image-wrapper {
        display: flex;
        align-items: center;
        flex: 50% 0 0;
        justify-content: center;
    }

    &__content {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        padding: 50px;

        @include mobile {
            align-items: center;
            padding: 0;
        }

        .about & {
            padding: 40px;
        }
        .section__desc {
            text-align: left;

            @include mobile {
                text-align: center;
            }
        }
    }
}
