@import '../../styles/colors.scss';

.about {
    &__image {
        width: 100%;
        max-width: 350px;
        height: 100%;

        border-radius: 30px;

        object-fit: contain;
    }

    &__title {
        margin: 50px;
    }
}
